import "./Footer.css"
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <div className="main-footer mt-10">
            <div className="container">
                <div className="row">
                    {/* Column1 */}
                    <div className="col">
                        <h4>Fire Games INC</h4>
                        <ul className="list-unstyled">
                            <li>2023-2024</li>
                            <li>Annecy, France</li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                        </ul>
                    </div>
                    {/* Column2 */}
                    <div className="col">
                        <h4>Social media</h4>
                        <ul className="list-unstyled">
                            <li><a href="https://www.youtube.com/@FireGames-studio" target="_blank">Youtube</a></li>
                            <li><a href="https://www.youtube.com/@FireGamesFrance" target="_blank">Youtube FR</a></li>
                            <li><a href="https://www.x.com/theFireGamesDev" target="_blank">X</a></li>
                            <li><a href="https://www.discord.gg/4HjGy2nFSn" target="_blank">Discord</a></li>
                            <li><a href="https://www.tiktok.com/@thefiregamesdev" target="_blank">Tiktok</a></li>
                            <li><a href="https://www.reddit.com/r/FireGamesCommunity/" target="_blank">Reddit</a></li>
                        </ul>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <p className="col-sm">
                        &copy;{new Date().getFullYear()} | Fire Games | All right reserved
                    </p>
                </div>
            </div>
        </div>
    )
}