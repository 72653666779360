import { FaYoutube, FaDiscord, FaTiktok, FaRedditAlien } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'

export const SocialLinks = () => {
    return (
        <section>
            <ul className="flex space-x-10">
                <li><a href="https://www.youtube.com/@FireGames-studio" target="_blank"> <FaYoutube/> </a></li>
                <li><a href="https://www.x.com/theFireGamesDev" target="_blank"> <FaXTwitter/> </a></li>
                <li><a href="https://www.discord.gg/4HjGy2nFSn" target="_blank"> <FaDiscord/> </a></li>
                <li><a href="https://www.tiktok.com/@thefiregamesdev" target="_blank"> <FaTiktok/> </a></li>
                <li><a href="https://www.reddit.com/r/FireGamesCommunity/" target="_blank"> <FaRedditAlien/> </a></li>
            </ul>
        </section>
    )
}