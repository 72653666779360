import './About.css'

export const About = () => {
    return (
        <section className="text bg-[#333333] min-h-screen">
            <h1 className="text-4xl font-extrabold flex justify-center">About</h1>
            <p className="mt-5 flex justify-center">Fire Games is an independent French studio created and mainly based in Annecy.<br/> It was founded in 2023 by 2 enthusiasts and now has 10 members in this crazy project.<br/> With great ambitions, this studio has only one goal, to create the best games possible.<br/> Their greatest strength: their common dream, that of becoming a world-renowned studio.<br/> Today in 2024, the studio was able to publish Jump Heat, their first game, on the occasion of<br/> the SpeedJam. But don't worry, they're not going to stop there...</p>
            <h2 className="text-4xl font-extrabold flex justify-center mt-10">Life at the studio:</h2>
            <p className=" flex justify-center mt-5">Fire Games is entirely online and therefore has no offices.<br/> With a development team of 5 people, 2 graphic designers,<br/> a composer, a 3d artist and 2 game designers, the studio is<br/> ready to face any challenge that may arise in front of them.</p>
            <p className="mt-10 flex justify-center text-2xl font-extrabold">Timeline:</p>
            <p className="mt-5 flex justify-center">
                End of 2023: creation of Fire Games<br/>
                Early 2024: start with a first development team<br/>
                June 2024: new team<br/>
                July 2024: release of Jump Heat<br/>
                August 2024: arrival of new members in the team<br/>
            </p>
        </section>
    )
}