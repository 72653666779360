import React, { useState } from "react"; // Import useState
import "./contact.css";
import { useForm, SubmitHandler } from "react-hook-form";
import emailjs from "@emailjs/browser";

type FormInputs = {
    subject: string,
    email: string,
    message: string
}

export const Contact = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<FormInputs>();
    const [messageSent, setMessageSent] = useState(false); // State to track submission status

    const onSubmit: SubmitHandler<FormInputs> = data => {
        emailjs.send(
            'service_xny8fzn',
            'template_vg817ss',
            {
                subject: data.subject,
                email: data.email,
                message: data.message
            },
            '_0F0RE84EjxKmdzvo'
        ).then(() => {
            setMessageSent(true); // Set messageSent to true on successful submission
        }).catch(() => {
            setMessageSent(false); // Optionally handle error case
        });
    }

    return (
        <main className="ml-5 text-[#ffffff]">
            <h1 className="font-extrabold mb-4 text-3xl flex justify-center">Contact</h1>
            <h2 className="font-extrabold mb-4 text-2xl flex justify-center">Contact us by email :</h2>

            <p className="flex justify-center text-2xl">contact@studiofiregames.fr</p>

            <div className="contact-container">
                <form className="contact-left" onSubmit={handleSubmit(onSubmit)}>
                    <div className="contact-left-title">
                        <h3>Contact us by form :</h3>
                        <hr />
                    </div>
                    <div className="mb-3">
                        <label className="block" htmlFor="subject">Subject line</label>
                        <input {...register("subject", { required: true })} className="contact-inputs" type="text" name="subject" id="subject" placeholder="What is the subject?" />
                        {errors.subject && <p className="text-[red]">required subject</p>}
                    </div>
                    <div className="mb-3">
                        <label className="block" htmlFor="email">Email</label>
                        <input {...register("email", { required: true })} className="contact-inputs" type="email" name="email" id="email" placeholder="Your email" />
                        {errors.email && <p className="text-[red]">required email</p>}
                    </div>
                    <div className="mb-3">
                        <label className="block" htmlFor="message">Message</label>
                        <textarea {...register("message", { required: true })} rows={5} className="contact-inputs resize-none" name="message" id="message" placeholder="Your message" />
                        {errors.message && <p className="text-[red]">required message</p>}
                        {messageSent && <p className="success-message">Your message has been sent !</p>}
                    </div>
                    <input type="submit" className="Button" />
                </form>
            </div>
        </main>
    );
};
