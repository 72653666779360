export const Games = () => {
    return (
        <main className="text-[#ffffff]">
            <div className="mt-10">
            <h1 className="flex justify-center font-extrabold text-5xl">Fire Games</h1>
            <p className="flex justify-center">We are Fire Games</p>
            </div>
            <h2 className="flex justify-center font-extrabold text-4xl mb-4 mt-10">Our Games :</h2>
            <h3 className="flex justify-center font-extrabold text-3xl mb-7 mt-10">JumpHeat :</h3>
            <div className="flex justify-center">
            <img src="images/JumpHeat.WebP" alt="image de JumpHeat"/>
            </div>
            <div className="mt-5 flex justify-center">
            <p><a className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition-colors duration-300" href="https://fire-games-studio-off.itch.io/jump-heat" target="_blank">Play now</a></p>
            </div>
        </main>
    )
}