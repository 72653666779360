import React from 'react';
import './components/NavBar.css';
import { Games } from './screens/Games';
import { Contact } from './screens/Contact';
import { About } from './screens/About';
import { PrivacyPolicy } from './screens/PrivacyPolicy';
import { Routes, Route, NavLink } from 'react-router-dom';
import { SocialLinks } from './components/SocialLinks';
import { Footer } from './components/Footer';

function App() {
  return (
    <div>
      <header 
        className='navbarorganization'
        style={{
          backgroundImage: "url('images/headerbackground.WebP')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100px'
        }}
      >
        <nav className="navbar">
          <NavLink className='ml-20 text-3xl' style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/'>Games</NavLink>
          <NavLink className='ml-7 text-3xl' style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/about'>About</NavLink>
          <NavLink className='ml-7 text-3xl' style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/contact'>Contact</NavLink>
        </nav>

        <label className='hamburger-menu'>
          <input type="checkbox" />
        </label>

        <aside className='sidebar'>
          <nav>
            <NavLink style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/'>Games</NavLink><br/>
            <NavLink style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/about'>About</NavLink><br/>
            <NavLink style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/contact'>Contact</NavLink>
          </nav>
        </aside>

        <div className="logo">
          <img src="images/logo.AVIF" alt="Logo de Fire Games" />
        </div>
        <div className='mr-20 text-3xl sociallinks'>
          <SocialLinks />
        </div>
      </header>
      <Routes>
        <Route path='/' element={<Games />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      </Routes>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
